import React from 'react';
import {  } from '../assets';

const Leadership = () => {
  return (
    <div className="flex flex-col items-center w-full h-full">
      <div className="flex flex-col w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full h-fit max-md:px-[10px]">
        {/* Title */}
        <div className='flex m-2 text-[#31bfff] h-[80px] bg-white rounded-xl p-2 justify-center items-center text-center text-4xl max-md:text-3xl max-sm:text-2xl font-bold'>Leadership</div>
      </div>
      {/* Leaders */}
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="bg-white h-fit w-full">
          <div className='text-justify'>LECO is governed by the Organisation Board under advisory of the Annual General meeting, while the daily activities are run by management. The Organisation Board consists of the Chairperson, Vice Chairperson, Secretary to the Board, Executive Director, Treasurer and at least two members appointed by the Board. The management consists of the Executive Director, Treasurer and Heads of Department.</div>
        </div>
      </div>
    </div>
  );
};

export default Leadership;