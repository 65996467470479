import React from 'react';
import { orgstructure } from '../assets';

const Membership = () => {
  return (
    <div className="flex flex-col items-center w-full h-full">
      <div className="flex flex-col w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full h-fit max-md:px-[10px]">
        {/* Title */}
        <div className='flex m-2 text-[#31bfff] h-[80px] bg-white rounded-xl p-2 justify-center items-center text-center text-4xl max-md:text-3xl max-sm:text-2xl font-bold'>Membership</div>
      </div>
      {/* Registration */}
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="h-fit w-full">
          <div>
            <div className='font-bold text-teal-500'>There are three types of LECO membership:</div>
            <div className='mt-3'>
              <div className=''>
                <div className='font-medium'>(a) Founding members</div>
                <div className='ml-[15px] text-justify'>Members who actively participated in the initial stages of the conceptualization, discussion, implementation and registration of LECO.</div>
              </div>
            </div>
            <div className='mt-3'>
              <div className=''>
                <div className='font-medium'>(b) Ordinary members</div>
                <div className='ml-[15px] text-justify'>A person who joins LECO through application, affiliation or co-option, provided that person:</div>
                <ol type="1" className='ml-[40px]' style={{ listStyleType: 'decimal' }}>
                  <li>Is a citizen of Tanzania;</li>
                  <li>Is 18 years old or above;</li>
                  <li>Has a healthy mind and is able to think normally;</li>
                  <li>Willingly accepts, supports and acts in accordance with the constitution, objectives, guidelines and regulations of LECO.</li>
                </ol>
              </div>
            </div>
            <div className='mt-3'>
              <div className='font-medium'>(c)	Affiliated and honorary members</div>
              <div className='ml-[15px] text-justify'>A person, organisation or association can become an affiliated (or honorary) member of LECO through recommendation by the Management and approval by the Board, provided that person has the qualities of an ordinary member and:</div>
              <ol type="1" className='ml-[40px]' style={{ listStyleType: 'decimal' }}>
                <li>Has interests similar or relevant to those of LECO;</li>
                <li>Has great potential to support LECO or has affiliation with an organisation or institution that supports LECO.</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/* How to register */}
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="h-fit w-full text-justify text-emerald-500">A person who aspires to become a member of LECO shall complete the application form (retrievable from the Executive Director) and submit it online to the same office.</div>
      </div>
      {/* Organisation Structure */}
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="bg-white h-fit py-4 w-full">
          <div className='flex flex-col w-full items-center justify-center'>
            <div className='text-[25px] text-center mb-4 max-md:text-[20px] font-bold text-[#ffca05]'>Organisation Structure</div>
            <img src={orgstructure} className="w-[400px] h-auto object-cover" />
          </div>
        </div>
      </div>
      {/* Contributions & Donations */}
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="h-fit w-full">
        <div className='text-[25px] text-center max-md:text-[20px] font-bold text-[#ffca05]'>Contributions & Donations</div>
          <div className='text-justify'>LECO requests members, partners, donors and stakeholders for resources and research funds to enhance our progress in achieving organisation objectives, that is:</div>
          <ol type="1" className='ml-[40px]' style={{ listStyleType: 'decimal' }}>
            <li>Grants, donations and legacies or bequests;</li>
            <li>Payments for training, contract services and consultation fees;</li>
            <li>Purchasing products, publications and innovations;</li>
            <li>Subscription for database access and showcases.</li>
          </ol>
        </div>
      </div>
      {/* Registered Members */}
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="h-fit w-full">
          <div className='text-[25px] text-center max-md:text-[20px] font-bold text-[#ffca05]'>Registered Members</div>
          <div className='text-justify'>The person qualifies as a registered member after meeting the criteria, including payment of registration and subscription fees determined by the Board.</div>
          <ol type="1" className='ml-7' style={{ listStyleType: 'decimal' }}>
            <li className=''>Prof. Henry R. Muzale</li>
            <li className=''>Prof Josephat M. Rugemalira</li>
            <li className=''>Malise Swila</li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Membership;