import React from 'react';

const Notices = () => {
  return (
    <div className="flex flex-col items-center w-full h-full">
      <div className="flex flex-col w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full h-fit max-md:px-[10px]">
        {/* Title */}
        <div className='flex m-2 text-[#31bfff] h-[80px] bg-white rounded-xl p-2 justify-center items-center text-center text-4xl max-md:text-3xl max-sm:text-2xl font-bold'>Notices</div>
      </div>
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="bg-white h-fit py-4 w-full">
          <div className='text-[25px] text-center my-2 max-md:text-[20px] font-bold text-[#ffca05]'>Calendar and Plans</div>
        </div>
      </div>
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="bg-white h-fit py-4 w-full">
          <div className='text-[25px] text-center my-2 max-md:text-[20px] font-bold text-[#ffca05]'>Announcements</div>
        </div>
      </div>
      {/* <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="bg-white h-fit py-4 w-full">
          <div className='text-[25px] text-center my-2 max-md:text-[20px] font-bold text-[#ffca05]'>Gallery</div>
        </div>
      </div> */}
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="h-fit w-full">
          <div className='text-[25px] text-center my-2 max-md:text-[20px] font-bold text-[#ffca05]'>Consultants</div>
          <div className='grid grid-cols-4 max-md:grid-cols-2'>
            <div className='mb-3'>
              <div className='font-bold text-blue-500'>Prof Henry R. Muzale</div>
              <div>+255 755 007 005</div>
              <div>hmuzale@gmail.com</div>
            </div>
            <div className='mb-3'>
              <div className='font-bold text-blue-500'>Prof Josephat M. Rugemalira</div>
              <div>+255 713 212 161</div>
              <div>josephatr@hotmail.com</div>
            </div>
            <div className='mb-3'>
              <div className='font-bold text-blue-500'>Prof Rose A. Upor </div>
              <div>+255 782 254 541</div>
              <div>roseupor@gmail.com</div>
            </div>
            <div className='mb-3'>
              <div className='font-bold text-blue-500'>Dr Julius J. Taji</div>
              <div>+255 714 887 713</div>
              <div>juliustaji@gmail.com</div>
            </div>
            <div className='mb-3'>
              <div className='font-bold text-blue-500'>Mr Valentine K. Karugaba</div>
              <div>+255 713 332 028</div>
              <div>valekarugaba@yahoo.com</div>
            </div>
            <div className='mb-3'>
              <div className='font-bold text-blue-500'>Dr Aurelia Mallya</div>
              <div>+255 757 608 026</div>
              <div>auremsaki@yahoo.com</div>
            </div>
            <div className='mb-3'>
              <div className='font-bold text-blue-500'>Mr Novath Rukwago</div>
              <div>+255 762 406 785</div>
              <div>novatrk@yahoo.com</div>
            </div>
            <div className='mb-3'>
              <div className='font-bold text-blue-500'>Dr Nicholaus Asheli </div>
              <div>+255 713 817 172</div>
              <div>niconjeza@yahoo.co.uk</div>
            </div>
            <div className='mb-3'>
              <div className='font-bold text-blue-500'>Ms Malise Swila</div>
              <div>+255 784 600 292</div>
              <div>swilamalise5@gmail.com</div>
            </div>
            <div className='mb-3'>
              <div className='font-bold text-blue-500'>Mr Selgius Ntechi</div>
              <div>+255 679 708 007</div>
              <div>selgiusp@gmail.com</div>
            </div>
            <div className='mb-3'>
              <div className='font-bold text-blue-500'>Mr Thomas Shayo</div>
              <div>+255 713 560 058</div>
              <div>tomshayo12@gmail.com</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notices;