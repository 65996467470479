import React from 'react';
import { hrtmuzale, Logo, Picture1, Picture2, Picture3, Picture4, Picture5, Picture6 } from '../assets';
import { WelcomeNotes } from '../Data';
import { Link } from 'react-router-dom';
import ImageSlider from './../components/ImageSlider';

const Home = () => {
  return (
    <div className="flex flex-col items-center w-full h-full">
      {/* <div className="flex flex-col w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full h-fit max-md:px-[10px]">
        <img className="h-[380px] w-full" />
      </div> */}
      <ImageSlider />
      {/* Title */}
      <div className='flex flex-col w-full h-fit bg-[#31bfff] text-center text-white px-10 py-4 max-sm:max-h-20'>
        <p className='text-[35px] max-md:text-[25px] max-sm:text-[18px] font-bold'>Language, Education and Culture Organisation</p>
        {/* <p className='text-[18px] max-md:text-[16px] max-sm:text-[14px] font-medium'>Language for Knowledge and Technology</p> */}
      </div>
      <div className='flex w-full justify-center items-center bg-white'>
        <div className="flex gap-4 max-md:gap-2 max-md:flex-col my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full h-fit max-md:px-[10px]">
          <div className="h-[300px] w-[540px] max-md:w-full flex justify-center items-center">
            <img src={Logo} className="bg-white h-full w-[300px] object-cover" />
          </div>
          <div className="flex flex-col bg -white h-fit py-2 w-[540px] max-md:w-full text-justify">
            <p className='text-[25px] max-md:text-[20px] font-bold'>Welcome Note</p>
            <div>{WelcomeNotes} <Link to={`/about-us`} className='text-blue-600 font-semibold hover:underline'>Learn more</Link></div>
          </div>
        </div>
      </div>
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="flex flex-row-reverse max-md:flex-col gap-4 bg- white min-h-[120px] h-fit p-2 w-full">
          <div className='flex flex-col h-full w-[30%] max-md:w-full items-center'>
            <img src={hrtmuzale} className="bg-white w-[50%] object-cover" />
            <div className='text-center font-bold text-rose-700'>HRT Muzale</div>
            <div className='text-center text-slate-700 font-medium text-sm'>Executive Director</div>
          </div>
          <div className="h-full w-[70%] max-md:w-full">
            <div className='w-full text-justify text-purple-900'>Whether you are a researcher, educator, linguist, learner, trainer, documenter, consultant, culturalist, traditionalist, technologist, or just an interested stakeholder in any issue related to this organisation, LECO welcomes you to participate in its activities toward achievement of its goal and objectives. Given the number of languages spoken in Tanzania, the impending endangerment for local community languages, overwhelming challenges in language in education and communication skills, and the growing demand for the use of technology, especially artificial intelligence, in language and education, we cannot wait to see you jump on the bandwagon to save our community heritage for posterity.</div>
            <div className='w-full text-justify my-4 text-blue-500'>Our Motto is “<strong>Language is Knowledge and Heritage</strong>”.</div>
          </div>
        </div>
      </div>
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="bg-white h-fit pb-2 w-full">
          <Link to={`/about-us`}>
            <div className='text-[25px] text-center my-2 max-md:text-[20px] font-bold text-[#ffca05]'>Statement on the languages of Tanzania</div>
          </Link>
          <div className='flex flex-wrap gap-1 w-full items-center justify-center'>
            <img src={Picture1} className="bg-white w-[15%] max-md:w-[32%] h-[220px] hover:h-auto transition-transform duration-300 transform-gpu hover:scale-110 object-cover" />
            <img src={Picture2} className="bg-white w-[15%] max-md:w-[32%] h-[220px] hover:h-auto transition-transform duration-300 transform-gpu hover:scale-110 object-cover" />
            <img src={Picture3} className="bg-white w-[15%] max-md:w-[32%] h-[220px] hover:h-auto transition-transform duration-300 transform-gpu hover:scale-110 object-cover" />
            <img src={Picture4} className="bg-white w-[15%] max-md:w-[32%] h-[220px] hover:h-auto transition-transform duration-300 transform-gpu hover:scale-110 object-cover" />
            <img src={Picture5} className="bg-white w-[15%] max-md:w-[32%] h-[220px] hover:h-auto transition-transform duration-300 transform-gpu hover:scale-110 object-cover" />
            <img src={Picture6} className="bg-white w-[15%] max-md:w-[32%] h-[220px] hover:h-auto transition-transform duration-300 transform-gpu hover:scale-110 object-cover" />
          </div>
          <div className='flex flex-col w-full mt-1 items-center justify-center'>
            <div className='text-justify w-[90%] max-md:w-full'>Tanzania is a country rich in language and cultural diversity in the sense that many people speak three languages. The three-tier sociolinguistic situation distributes languages in three functional layers with distinctive statuses and attitudes associated with each layer and its embedded culture. The uppermost layer consists primarily of English, a tremendously powerful and prestigious official language, followed by the second layer that consists of Kiswahili, the national-cum-official language and the language of wider communication countrywide.</div>
            <Link to={`/about-us`} className='text-blue-500 font-semibold hover:underline'>Learn more</Link>
          </div>
        </div>
      </div>
      <div className='flex flex-col w-full h-fit bg-zinc-800 items-center justify-center text-white'>
        <div className="flex flex-col w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full h-full max-md:px-[10px]">
          <div className='text-[25px] text-center my-2 max-md:text-[20px] font-bold text-[#ae502b]'>Code and Values</div>
          <div className='flex max-md:flex-col'>
            <div className="flex gap-1 flex-col h-fit py-2 w-full">
              <div>a.	Excellence</div>
              <div>b.	Volunteerism</div>
              <div>c.	Human rights</div>
            </div>
            <div className="flex gap-1 flex-col h-fit py-2 w-full">
              <div>d.	Mutual respect</div>
              <div>e.	Professionalism</div>
              <div>f.	Linguistic rights</div>
            </div>
            <div className="flex gap-1 flex-col h-fit py-2 w-full">
              <div>g.	Unity and solidarity</div>
              <div>h.	Honesty and transparency</div>
              <div>i.	Resilience and perseverance</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-2 my-2 w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full max-md:px-[10px]">
        <div className="bg-white h-[120px] w-full"></div>
      </div>
    </div>
  );
};

export default Home;