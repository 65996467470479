import React, { useState } from "react";
import "./styles.css";

export default function FAQ() {
    const [faqs, setFaqs] = useState([
        {
            question: "What is LECO?",
            answer: [
                "Answer.",
            ],
            open: false
        },
        {
            question: "What does LECO do?",
            answer: [
                "Line 1.",
                "Line 2.",
                "Line 2.",
            ],
            open: false
        },
    ]);

    const toggleFAQ = index => {
        setFaqs(prevFaqs =>
            prevFaqs.map((faq, i) => ({
                ...faq,
                open: i === index ? !faq.open : false
            }))
        );
    };

    const FAQUI = ({ faq, index }) => {
        return (
            <div
                className={"faq " + (faq.open ? "open" : "")}
                key={index}
            >
                <div onClick={() => toggleFAQ(index)} className="faq- question faq-zoom-effect text-[#31bfff] flex p-2 rounded-[10px] min-h-[60px] items-center font-bold text-large mb-1">{faq.question}</div>
                <div className="faq-answer">
                    {faq.answer.map((answer, idx) => (
                        <p key={idx} className="mb-5 px-5">{answer}</p>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="App">
            <div className="faqs">
                {faqs.map((faq, index) => (
                    <FAQUI faq={faq} index={index} key={index} />
                ))}
            </div>
        </div>
    );
}
