import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { navlogo } from '../assets';
import { CgMenuRight } from "react-icons/cg";
import { RiCloseFill } from "react-icons/ri";

const Navbar = () => {
    const location = useLocation();
    const [active, setActive] = useState(getActiveLink(location.pathname));
    const [showDropdown, setShowDropdown] = useState({ documentation: false, aboutUs: false });
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    function getActiveLink(pathname) {
        if (pathname.includes('/about-us') || pathname.includes('/leadership')) {
            return 'About Us';
        }
        if (pathname.includes('/documentation') || pathname.includes('/notices') || pathname.includes('/learning')) {
            return 'Documentation';
        }

        const navLinks = [
            { id: '', title: 'Home' },
            { id: 'about-us', title: 'About Us' },
            { id: 'membership', title: 'Membership' },
            { id: 'documentation', title: 'Documentation' },
            { id: 'contact-us', title: 'Contact Us' },
        ];
        const link = navLinks.find((nav) => `/${nav.id}` === pathname);
        return link ? link.title : 'Home';
    }

    return (
        <>
            <div className="flex flex-col h-[30px] max-md:hidden justify-center items-center w-full max-lg:w-[720px] max-md:w-[512px] max-sm:w-full bg-white p-1">
                <div className="flex h-full justify-between items-center w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full bg-blue-600 rounded-full px-2 py-1">
                    <div className="flex text-white justify-start text-xs font-medium">
                        Mon - Fri 3.00 - 17.00. Saturday & Sunday CLOSED
                    </div>
                    <div className="flex max-sm:gap-2 text-white gap-5 justify-end text-xs">
                        <Link to={`/contact-us`} className="cursor-pointer font-medium hover:text-[12.4px] text-center w-[100px] transition-transform duration-300 transform-gpu hover:scale-110">GET IN TOUCH</Link>
                        <a href='https://admin.leco.or.tz' target='_blank' className="cursor-pointer font-medium hover:text-[12.4px] text-center w-[50px] transition-transform duration-300 transform-gpu hover:scale-110">LOGIN</a>
                        <a href="tel:+255760115555 " className="cursor-pointer font-medium hover:text-[12.4px] text-center w-[160px] transition-transform duration-300 transform-gpu hover:scale-110">Call Us. (+255) 760 115 555 </a>
                    </div>
                </div>
            </div>
            <div className="bg-[#31bfff] h-[70px] w-full flex items-center justify-center">
                <div className="flex items-center w-[1080px] max-lg:w-[720px] max-md:w-[512px] max-sm:w-full p-1 max-md:pl-4 justify-between">
                    <Link to={`/`} className="h-[60px] w-[60px]">
                        <img src={navlogo} className='h-[60px] w-[60px] object-cover' />
                    </Link>
                    <div className="flex text-white max-md:hidden">
                        <Link
                            to="/"
                            onClick={() => setActive('Home')}
                            className={`w-[100px] h-[70px] flex cursor-pointer items-center justify-center text-center ${active === 'Home' ? 'bg-[#00a6f1] text-white max-md:rounded-xl ' : 'hover:bg-[#00aeff] hover:text-white max-md:rounded-xl '
                                }`}
                        >
                            Home
                        </Link>

                        {/* About Us with Dropdown */}
                        <div
                            className="relative group"
                            onMouseEnter={() => setShowDropdown({ ...showDropdown, aboutUs: true })}
                            onMouseLeave={() => setShowDropdown({ ...showDropdown, aboutUs: false })}
                        >
                            <Link
                                to="/about-us"
                                onClick={() => setActive('About Us')}
                                className={`w-[100px] h-[70px] flex cursor-pointer items-center justify-center text-center gap-[2px] ${active === 'About Us' ? 'bg-[#00a6f1] text-white max-md:rounded-xl ' : 'hover:bg-[#00aeff] hover:text-white max-md:rounded-xl '
                                    }`}
                            >
                                About Us
                                {showDropdown.aboutUs ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
                            </Link>
                            {showDropdown.aboutUs && (
                                <div className="absolute left-0 mt-0 bg-white shadow-lg text-black flex flex-col">
                                    <Link
                                        to="/about-us"
                                        className="p-2 hover:bg-gray-200 max-md:rounded-xl "
                                        onClick={() => setActive('About Us')}
                                    >
                                        About Us
                                    </Link>
                                    <Link
                                        to="/leadership"
                                        className="p-2 hover:bg-gray-200 max-md:rounded-xl "
                                        onClick={() => setActive('About Us')}
                                    >
                                        Leadership
                                    </Link>
                                </div>
                            )}
                        </div>

                        <Link
                            to="/membership"
                            onClick={() => setActive('Membership')}
                            className={`w-[120px] h-[70px] flex cursor-pointer items-center justify-center text-center ${active === 'Membership' ? 'bg-[#00a6f1] text-white max-md:rounded-xl ' : 'hover:bg-[#00aeff] hover:text-white max-md:rounded-xl '
                                }`}
                        >
                            Membership
                        </Link>

                        {/* Documentation with Dropdown */}
                        <div
                            className="relative group"
                            onMouseEnter={() => setShowDropdown({ ...showDropdown, documentation: true })}
                            onMouseLeave={() => setShowDropdown({ ...showDropdown, documentation: false })}
                        >
                            <Link
                                to="/documentation"
                                onClick={() => setActive('Documentation')}
                                className={`w-[140px] h-[70px] flex cursor-pointer items-center justify-center text-center gap-[2px] ${active === 'Documentation' ? 'bg-[#00a6f1] text-white max-md:rounded-xl ' : 'hover:bg-[#00aeff] hover:text-white max-md:rounded-xl '
                                    }`}
                            >
                                Documentation
                                {showDropdown.documentation ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
                            </Link>
                            {showDropdown.documentation && (
                                <div className="absolute left-0 mt-0 bg-white shadow-lg text-black flex flex-col">
                                    <Link
                                        to="/documentation"
                                        className="p-2 hover:bg-gray-200 max-md:rounded-xl "
                                        onClick={() => setActive('Documentation')}
                                    >
                                        Documentations
                                    </Link>
                                    <Link
                                        to="/notices"
                                        className="p-2 hover:bg-gray-200 max-md:rounded-xl "
                                        onClick={() => setActive('Documentation')}
                                    >
                                        Notices
                                    </Link>
                                    <Link
                                        to="/learning"
                                        className="p-2 hover:bg-gray-200 max-md:rounded-xl "
                                        onClick={() => setActive('Documentation')}
                                    >
                                        Learning and Unlearning
                                    </Link>
                                </div>
                            )}
                        </div>

                        <Link
                            to="/contact-us"
                            onClick={() => setActive('Contact Us')}
                            className={`w-[100px] h-[70px] flex cursor-pointer items-center justify-center text-center ${active === 'Contact Us' ? 'bg-[#00a6f1] text-white max-md:rounded-xl ' : 'hover:bg-[#00aeff] hover:text-white max-md:rounded-xl '
                                }`}
                        >
                            Contact Us
                        </Link>
                    </div>
                </div>
                <div onClick={() => setIsMenuOpen(!isMenuOpen)} className="md:hidden cursor-pointer">
                    {isMenuOpen ? (
                        <RiCloseFill className="text-white mx-3 h-[40px] w-[40px]" />
                    ) : (
                        <CgMenuRight className="text-white mx-3 h-[40px] w-[40px]" />
                    )}
                </div>
            </div>

            {/* Mobile Menu */}
            {isMenuOpen && (
                <div
                    className={`bg-white p-4 mt-[-8px] shadow-xl ${!isMenuOpen ? "hidden" : "flex flex-col"
                        } p-2 bg-black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[260px] max-md:rounded-xl  sidebar md:hidden`}
                >
                    <Link
                        to="/"
                        onClick={() => {
                            setActive('Home');
                            setIsMenuOpen(false);
                        }}
                        className={`block py-2 px-4 text-sm ${active === 'Home' ? 'bg-[#00a6f1] text-white max-md:rounded-xl ' : 'hover:bg-[#00aeff] hover:text-white max-md:rounded-xl '}`}
                    >
                        Home
                    </Link>
                    <div className="relative">
                        <div
                            onClick={() => setShowDropdown({ ...showDropdown, aboutUs: !showDropdown.aboutUs })}
                            className={`flex justify-between items-center py-2 px-4 cursor-pointer text-sm ${active === 'About Us' ? 'bg-[#00a6f1] text-white max-md:rounded-xl ' : 'hover:bg-[#00aeff] hover:text-white max-md:rounded-xl '}`}
                        >
                            About Us
                            {showDropdown.aboutUs ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                        </div>
                        {showDropdown.aboutUs && (
                            <div className="pl-4">
                                <Link
                                    to="/about-us"
                                    onClick={() => {
                                        setActive('About Us');
                                        setIsMenuOpen(false);
                                    }}
                                    className="block py-1 px-4 hover:bg-gray-200 max-md:rounded-xl "
                                >
                                    About Us
                                </Link>
                                <Link
                                    to="/leadership"
                                    onClick={() => {
                                        setActive('About Us');
                                        setIsMenuOpen(false);
                                    }}
                                    className="block py-1 px-4 hover:bg-gray-200 max-md:rounded-xl "
                                >
                                    Leadership
                                </Link>
                            </div>
                        )}
                    </div>
                    <Link
                        to="/membership"
                        onClick={() => {
                            setActive('Membership');
                            setIsMenuOpen(false);
                        }}
                        className={`block py-2 px-4 text-sm ${active === 'Membership' ? 'bg-[#00a6f1] text-white max-md:rounded-xl ' : 'hover:bg-[#00aeff] hover:text-white max-md:rounded-xl '}`}
                    >
                        Membership
                    </Link>
                    <div className="relative">
                        <div
                            onClick={() => setShowDropdown({ ...showDropdown, documentation: !showDropdown.documentation })}
                            className={`flex justify-between items-center py-2 px-4 cursor-pointer text-sm ${active === 'Documentation' ? 'bg-[#00a6f1] text-white max-md:rounded-xl ' : 'hover:bg-[#00aeff] hover:text-white max-md:rounded-xl '}`}
                        >
                            Documentation
                            {showDropdown.documentation ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                        </div>
                        {showDropdown.documentation && (
                            <div className="pl-4">
                                <Link
                                    to="/documentation"
                                    onClick={() => {
                                        setActive('Documentation');
                                        setIsMenuOpen(false);
                                    }}
                                    className="block py-1 px-4 hover:bg-gray-200 max-md:rounded-xl "
                                >
                                    Documentation
                                </Link>
                                <Link
                                    to="/notices"
                                    onClick={() => {
                                        setActive('Documentation');
                                        setIsMenuOpen(false);
                                    }}
                                    className="block py-1 px-4 hover:bg-gray-200 max-md:rounded-xl "
                                >
                                    Notices
                                </Link>
                                <Link
                                    to="/learning"
                                    onClick={() => {
                                        setActive('Documentation');
                                        setIsMenuOpen(false);
                                    }}
                                    className="block py-1 px-4 hover:bg-gray-200 max-md:rounded-xl "
                                >
                                    Learning and Unlearning
                                </Link>
                            </div>
                        )}
                    </div>
                    <Link
                        to="/contact-us"
                        onClick={() => {
                            setActive('Contact Us');
                            setIsMenuOpen(false);
                        }}
                        className={`block py-2 px-4 text-sm ${active === 'Contact Us' ? 'bg-[#00a6f1] text-white max-md:rounded-xl ' : 'hover:bg-[#00aeff] hover:text-white max-md:rounded-xl '}`}
                    >
                        Contact Us
                    </Link>
                </div>
            )}
        </>
    );
};

export default Navbar;